.audio_message {
}

.audio_message::-webkit-media-controls-panel {
  background-color: #f7941d;
  padding: 0.2rem;
}
.audio_message::-webkit-media-controls-panel {
}
.audio_message::-webkit-media-controls-volume-slider {
  /* width: 0; */
}
.audio_message::-webkit-media-controls-volume-slider-container:hover {
  /* width: 0; */
}

.audio_message::-webkit-media-controls-mute-button,
.audio_message::-webkit-media-controls-volume-slider-container,
.audio_message::-webkit-media-controls-volume-slider {
  /* display: none; */
  /* pointer-events: none; */
}

/* audio::-webkit-media-controls-mute-button
audio::-webkit-media-controls-play-button
audio::-webkit-media-controls-timeline-container
audio::-webkit-media-controls-current-time-display
audio::-webkit-media-controls-time-remaining-display
audio::-webkit-media-controls-timeline
audio::-webkit-media-controls-seek-back-button
audio::-webkit-media-controls-seek-forward-button
audio::-webkit-media-controls-fullscreen-button
audio::-webkit-media-controls-rewind-button
audio::-webkit-media-controls-return-to-realtime-button
audio::-webkit-media-controls-toggle-closed-captions-button */

