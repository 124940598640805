* {
  /* outline: 1px solid violet; */
}

html {
  overflow-x: hidden;
}

.__scrollbar_1 {
  scrollbar-width: thin;
  /* overflow-x: hidden; */
  overflow-y: scroll;
  overflow: overlay;
  position: relative;
}
.__scrollbar_1::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.5rem;
}
.__scrollbar_1::-webkit-scrollbar-thumb {
  border-radius: 1.2rem;
  background-color: #cfcfcf;
}
.__scrollbar_1::-webkit-scrollbar-thumb:hover {
  background-color: #bbbbbb;
}

/* SCROLLBAR 2 */
.__scrollbar_2 {
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow: overlay;
}
.__scrollbar_2::-webkit-scrollbar {
  width: 0.55rem;
  height: 0.6rem;
}
.__scrollbar_2::-webkit-scrollbar-thumb {
  border-radius: 1.2rem;
  background-color: #aaaaaa;
}
.__scrollbar_2::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

.__scrollbar_2x {
  scrollbar-width: thin;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: overlay;
}
.__scrollbar_2x::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.38rem;
}
.__scrollbar_2x::-webkit-scrollbar-thumb {
  border-radius: 1.2rem;
  background-color: #aaaaaa;
}
.__scrollbar_2x::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

.__scrollbar_3 {
  scrollbar-width: thin;
  /* overflow-x: hidden; */
  overflow-y: scroll;
  overflow: overlay;
  position: relative;
}
.__scrollbar_3::-webkit-scrollbar {
  width: 0.45rem;
  height: 0.5rem;
}
.__scrollbar_3::-webkit-scrollbar-thumb {
  border-radius: 1.2rem;
  background-color: #b8b8b8;
}
.__scrollbar_3::-webkit-scrollbar-thumb:hover {
  background-color: #a7a7a7;
}

/* .swiper-wrapper {
  display: flex;
  flex-direction: row-reverse;
} */
