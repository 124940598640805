@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'daisyui/dist/full.css';
@import url(./css/emojiPicker.css);

.layoutPadding {
  @apply px-[4%] md:px-[8%];
}

@layer base {
  ul,
  ol {
    list-style: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

.font-primary {
  color: #2d2d2d;
}
.font-secondary {
  color: #848484;
}

.text-16 {
  font-size: 16px;
}
.text-18 {
  font-size: 18px;
}
.text-20 {
  font-size: 20px;
}
.text-32 {
  font-size: 32px;
}

.fw-600 {
  font-weight: 600;
}
.fw-400 {
  font-weight: 400;
}
